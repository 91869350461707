/**
 * Anzeige des Bildes im Popup
 *
 * @method     picShow
 * @param      {string}   sCgiBin Aufzurufender Endpunkt
 * @param      {string}   id SessionId
 * @param      {string}   formElementName  Name des Input Fields
 * @param      {string}   formId         ID des Formulars auf der Seite
 */
function picShow(sCgiBin,id,formElementName, formId) {
    // Formular anhand der ID auswählen
    var form = document.getElementById(formId);
    if (form) {
        var src = form.elements[formElementName].value;
        if (src !== '') {
            picShowByPath(sCgiBin,id,src);
        }
    } else {
        console.error('Form not found');
    }
}

/**
 * Anzeige eines Bildes im Popup per Dateipfad
 *
 * @method     picShowByPath
 * @param      {string}           sCgiBin Aufzurufender Endpunkt
 * @param      {string}           ID SessionId
 * @param      {string}  src     Pfad des Bildes
 */
function picShowByPath(sCgiBin,id,src) {
	window.open(sCgiBin + '?HTML=admin/admctrl/www_pic_show.htm&VS_PIC_PATH=' + src + '&ID=' + id,'picture','location=no,resizable=yes,scrollbars=yes,status=no,menubar=no,width=650,height=550');
}
		
/**
 * Öffnet den Dateimanager (Upload/Selection)
 *
 * @method     browsePic
 * @param      {string}           sCgiBin Aufzurufender Endpunkt
 * @param      {string}           ID SessionId
 * @param      {string}           formElement  Name des Input Fields
 * @param      {string}           form         Index des Page Forms auf der Seite
 * @param      {string}           dirStart     Einstiegspunkt
 * @param      {(number|string)}  hideTree     Einstiegspunkt = Mountpoint, Übergeordnete Ordner werden ausgeblendet
 */
function browsePic(sCgiBin,id,formElement,form,dirStart,hideTree) {
	if(typeof dirStart === undefined)
		dirStart = '';
	if(typeof hideTree === undefined)
		hideTree = 0;
	window.open(sCgiBin + '?HTML=admin/admctrl/www_pic_select.htm&VS_COL_NAME=' + formElement + '&FORM=' + form + '&DIR_START=' + dirStart + '&HIDE_TREE=' + hideTree + '&ID=' + id, 'browse','location=no,resizable=yes,scrollbars=yes,status=no,menubar=no,width=950,height=650');
}



function fillAlias(form,field,val) {
	document.forms[form].elements['VS_ALIAS_VALUE'].value = val;
	evalFunc_caseSwitch('alphanum_x',val,'createForm','VS_ALIAS_VALUE');
}

	
function evalFunc_caseSwitch(type,inVal,formular,field)	{
	var theVal = inVal;
	var newString = '';
	
	for (var a=0;a<theVal.length;a++)	{
		var theChar = theVal.substr(a,1);
		var special = (theChar=='_'||theChar=='-');
		var white = (theChar==' ');
		var alpha = (theChar>='a'&&theChar<='z') || (theChar>='A'&&theChar<='Z');
		var num = (theChar>='0' && theChar<='9');
		switch(type)	{
			case "alphanum":	special=0;		break;
			case "alphanum_x":	white=0;		break;
			case "alpha":	num=0; special=0;	break;
			case "num":	alpha=0; special=0;		break;
		}
		if (alpha || num || white || special)	{
			newString+=theChar;
		}
		newString = newString.toLowerCase();
		
		document.forms[formular].elements[field].value=newString;
	}
}

