/**
 * Integra Internet Management GmbH 2015
 * sound specific js functions
 */

/**
 * play a given sound
 *
 * @param string soundName name of the soundfile without file ending
 * @param string elementId elemtid where to place the sound element in DOM - default: sound
 * @param string path - path where to find the sound files: default: admin/sound/
 * @param string autostart - autostart playing sound - default: true
 * @param string loop - loop sound - default: false
 * @param string controls - show control panel - default: false
 *
 * @see: http://www.w3schools.com/tags/tag_audio.asp
 */
function playSound(soundName, elementId, path, autostart, loop, controls){
	// fill default values
	elementId = typeof elementId !== 'undefined' ? elementId : "sound";
	path = typeof path !== 'undefined' ? path : "admin/sound/";
	autostart = typeof autostart !== 'undefined' ? autostart : "true";
	loop = typeof loop !== 'undefined' ? loop : "false";
	controls = typeof controls !== 'undefined' ? controls : "false";

	// prepare audioParams
	var audioParams = "";

	// check for autoplay
	if(autostart == "true")
		audioParams = audioParams + " autoplay";

	// check for loop
	if(loop == "true")
		audioParams = audioParams + " loop";

	// check for control panal
	if(controls == "true")
		audioParams = audioParams + " controls";

	// create audio tag
	document.getElementById(elementId).innerHTML='<audio' + audioParams + '><source src="' + path + soundName + '.mp3" type="audio/mpeg" /><source src="' + path + soundName + '.ogg" type="audio/ogg" /><embed hidden="true" autostart="' + autostart + '" loop="' + loop + '" src="' + path + soundName +'.mp3" /></audio>';
	
	//wenn man einige Formulare abschickt wird der sound noch mal abgeschickt, darum den Inhalt des div entfernen.
	//bei Problemen kann man diese option per paramter optional machen.
	setTimeout(function() {
    	// Entferne das Element audio aus dem sound div
    	document.getElementById(elementId).firstElementChild.remove();
	}, 1000)
}